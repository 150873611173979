// LOGIN PAGE
.account-creation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;

    a.btn {
        padding: 1rem 30px;
        @media screen and (min-width: 768px) {
            padding: 1rem 50px;
        }
    }

    p {
        color: var(--white);
    }
}
