// PUBLIC PAGE
main.public {

    >.head-content {
        @media screen and (min-width: 1680px) {
            max-width: 1680px;
        }

        @media screen and (min-width: 1920px) {
            max-width: 100%;
        }
    }


    >.wrapper-board {
        @media screen and (min-width: 1680px) {
            max-width: 1680px;
        }

        @media screen and (min-width: 1920px) {
            max-width: 100%;
        }


        >.head-content {
            @media screen and (min-width: 1680px) {
                max-width: 1680px;
            }

            @media screen and (min-width: 1920px) {
                max-width: 100%;
            }
        }

        >.my-profile {
            @media screen and (min-width: 1680px) {
                max-width: 1680px;
            }

            @media screen and (min-width: 1920px) {
                max-width: 100%;
            }
        }
    }

}
