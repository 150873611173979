// CITIZEN PROFILE PAGE
.my-profile {
    // STEPPER
    .step-container {
        display: flex;

        @media screen and (min-width: 1024px) {
            align-items: center;
            justify-content: space-around;
        }

        // CONTENT STEPS
        div.MuiStep-root {
            //margin-bottom: 1.875rem;
            @media screen and (min-width: 1024px) {
                padding-right: 2rem;
            }
        }

        .css-z7uhs0-MuiStepConnector-line { display: none; }

        span.MuiStepLabel-iconContainer {
            svg {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid var(--yellow);
                fill: var(--blue);
                text {
                    fill: var(--yellow);
                }
            }
        }

        span.Mui-active {
            svg {
                fill: var(--yellow);
                text {
                    fill: #00004F;
                }
            }
        }

        span.Mui-completed {
            svg {
                fill: var(--positif);
                border-color: var(--positif);
            }
        }

        // LABEL
        span.MuiStepLabel-label {
            color: var(--blue);
            font-family: var(--font-bold);
            font-size: 0.75rem;
            font-weight: 700;
            font-style: normal;
            text-transform: uppercase;
            margin-left: 0.75rem;
        }
    }
}
