// BLOCK CONTENT
.block-one-third{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.875rem;
    background-color: var(--white);
    z-index: 99;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        margin-bottom: 0
    }

    @media screen and (max-width: 1440px) {
        min-height: 650px;
    }

    &--left {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.875rem;

        @media screen and (min-width: 768px) {
            width: 57.56%;
            padding-top: 7.375rem;
            padding-bottom: 7.0625rem;
            justify-content: flex-start;
        }

        @media screen and (min-width: 1440px) {
            justify-content: center;
            padding-top:0;
            padding-bottom:0;
        }

        .content {
            @media screen and (min-width: 1440px){
                max-width: 500px;
            }
        }

        h2, p {
            color: var(--blue);
        }

        h2 {
            font-family: var(--font-extrabold);
            font-size: clamp(1.75rem, 1.2658rem + 2.0657vw, 3.125rem);
            line-height: clamp(2.375rem, 1.7808rem + 2.5352vw, 4.0625rem);
            //font-size: clamp(1.75rem, 2.361vw + 1rem, 3.125rem);
            //line-height: clamp(2.375rem, 2.291vw + 2rem, 4.063rem);
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 0;
        }

        p {
            font-size: clamp(0.875rem, 1vw + 0.39rem, 1rem);
            @media screen and (min-width: 1440px) {
                max-width: 390px;
            }
        }

        button.btn,
        a.btn {
            width: 100%;
            border: 0;
            margin-top: 21px;
            font-size: 13px;
            padding: 0.85rem 2.125rem;
            //padding: 1rem 3.25rem;

            @media screen and (min-width:768px) {
                font-size: 14px;
                max-width: 290px;
            }

            @media screen and (min-width:1440px) {
                font-size: 1rem;
                margin-top: 60px;
            }
        }
    }

    &--right {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @media screen and (min-width: 768px) {
            width: 42.44%;
        }

        img.block-image {
            width: 326px;
            height: 100%;
            object-fit: cover;

            @media screen and (min-width: 768px) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translateY(290px);
                z-index: 99;
            }


        }
    }
}
