/* ALERT NOTES */
.alert {
    position: relative;
    margin-bottom: 40px;

    @media screen and (min-width: 1680px) {
        max-width: 1680px;
    }

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;

        hr {
            display: none;
        }
    }

    // COL
    &-col {
        &:first-of-type{
            margin-right: 1.46875rem;
            flex:1;
            max-width: 80px;
        }

        &:last-of-type {
            margin-left: 1.46875rem;
            flex:3;
            width: calc(100% - 80px);
        }
    }

    // HEADER
    &-header {
        display: flex;

        &__content {
            width: 85%;
            margin-left: 1rem;

            h3, .title {
                font-size: 1rem;
                font-weight: 900;
                line-height: 1.5rem;
                color: var(--blue);
                margin-bottom: 0;

                @media screen and (min-width: 768px) {
                    font-size: 1.125rem;
                }
            }

            .date {
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: var(--blue);
                margin-top: 6px;
                margin-bottom: 0;
            }
        }

        // ICON
        &__icon {
            display: flex;
            justify-content: center;
            margin-right: 1rem;

            .icon {
                position: relative;
                display: flex;
                width: 50px;
                height: auto;
                padding-top: 1rem;

                @media screen and (min-width: 768px){
                    width: 80px;
                    padding-top: 1.125rem;
                }
            }
        }

    }

    // CONTENT
    &-content {
        @media screen and (min-width: 768px) {
            margin-top: -20px;
            padding-left: calc(80px + 2rem);
        }

        p.alert-date {
            font-size: 0.75rem;
            line-height: 1.25rem;
            margin-top: 6px;
            color: var(--blue);

            @media screen and (min-width: 768px) {
                font-size: 0.875rem;
            }
        }

        .message {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: var(--blue);
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .btn {
            width: 100%;
            font-weight: 900;
            padding: 10px 2rem;
            @media screen and (min-width: 768px) {
                width: fit-content;
            }
        }
    }

    // SPACE
    hr {
        margin-top: 2.5rem;
    }
}


.alert-container {

    >.alert {
        @media screen and (min-width: 1680px) {
            max-width: 1680px;
        }
    }


}
