// IMPORT FONT
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,600;6..12,700&family=Nunito:wght@400;600;700&display=swap');

// NUNITO LIGHT
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito Light';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/nunito-light.ttf') format('truetype');
}

// NUNITO REGULAR
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/nunito-regular.ttf') format('truetype');
}

// NUNITO BOLD
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/nunito-bold.ttf') format('truetype');
}

// NUNITO EXTRABOLD
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito Extrabold';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/nunito-extrabold.ttf') format('truetype');
}
