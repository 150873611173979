// ALERT IN PROGRESS - DETAILS
.table-alert {
    box-shadow: inherit!important;
}

.alert-progress {
    .alert-line {
        position: relative;
        font-family: var(--font-base);
        background: var(--Gris, #EFF2F3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        border-spacing:0 15px;
        margin: 1.8125rem 1.625rem;
        padding: 0.5rem;

        &:hover, &:focus {
            background-color: #ddd;
        }

        &--icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            max-width: 60px;
            height: 60px;
            padding: 0;
            margin-right: 1.25rem;

            span {
                width: 60px;
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--type {
            font-family: var(--font-bold);
            color: var(--blue);
            font-size: 1.125rem;
            line-height: 1.5rem;
            min-width: 180px;
            max-width: 180px;
            border: 0;
            padding-left: 1.25rem;
        }

        &--titre, &--start, &--end, &--warnings  {
            font-family: var(--font-base);
            color: var(--blue);
            font-size: 0.875rem;
            line-height: 1.25;
            border-left: 1px solid #E4E7E8!important;
            padding-left: 20px;
            padding-right: 20px;
            border: 0;
        }

        &--start, &--end {
            //width: calc(100% - (80px + 351px) / 2);
            min-width: 218px;
        }

        &--warnings {
            position: relative;
            display: flex;
            padding-right: 8px;
            width: 140px;

            .warnings { background-color: #FFB800; }
            .success { background-color: #6FCF97; }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: var(--font-base);
                color: var(--blue);
                font-size: 0.75rem;
                letter-spacing: -0.288px;
                padding: 5px 20px;
                gap: 5px;

                &:before {
                    content:'';
                    display: block;
                    width: 12px;
                    height: 12px;
                }

                &:before {
                    background-image: url('../../images/icons/cloche.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        &--status {
            position: relative;
            display: flex;
            border: 0;
            padding-left: 8px;

            button {
                display: flex;
                font-family: var(--font-bold);
                color: var(--blue);
                font-size: 0.75rem;
                letter-spacing: -0.288px;
                padding: 0px 1.25rem;
                gap: 0.3125rem;
                border-radius: 1rem;
            }

            .draft {
                background-color: var(--yellow);
                border: 0;
                &:hover, &:focus {
                    opacity: 0.8;
                }
            }

            .sending {
                background-color: var(--red);
                border: 0;
                &:hover, &:focus {
                    opacity: 0.8;
                }
            }

            .send_done {
                border-color: var(--blue);
                border: 0;
                &:hover, &:focus {}
            }
        }

        &--options {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            max-width: 80px;
            padding-left: 8px;

            button {
                width: 30px;
                height: 30px;
            }
        }

    }
}
