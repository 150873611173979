.wrapper-dashboard {
    main.main-content {
        height: 100%;
    }

    // CONTAINER ROOT
    .MuiContainer-root {
        background-color: var(--lightGray);
        padding-right: 0;

        @media screen and (min-width: 768px) {
            padding-right: 1rem;
        }
    }

    .MuiDrawer-paper {
        background-color: var(--blue);
    }

    // HEAD DASHBOARD
    .burgerMenu {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 65px;
        background-color: var(--blue);
        border-radius: 0;
        border-bottom: 1px solid var(--lightBlue);

        @media screen and (min-width: 768px) {
            width: 72px;
        }

        &:hover, &:focus {
            background-color: var(--yellow);
        }
    }

    .toolbar {
        position: relative;
        padding-left: 12px;

        &-content {
            position: relative;
            display: flex;
            justify-content: space-between;
            //width: calc(100% - 65px);
            padding: 0 1rem;
            padding-right: 0;
            @media screen and (min-width: 1024px){
                width: fit-content;
                padding: 0 2rem;
                padding-right: 0;
            }
        }
    }

    // MAIN CONTENT
    > main.main-content {
        background-color: var(--lightGray);

        .first-row {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            margin-bottom: 38px;

            @media screen and (min-width: 1024px) {
                flex-direction: row;
            }
        }
    }

    .welcomepanel,
    .actionpanels {
        width: 100%;

        @media screen and (min-width: 1024px){
            max-width: 330px;
        }

        @media screen and (min-width: 1600px) {
            max-width: 30%;
        }

        button.btn-square:first-of-type {
            @media screen and (min-width: 375px) {
                margin-left: 0;
            }
        }

    }


    // NEW CITIZEN STEPPER
    .step-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }

        // CONTENT STEPS
        span.MuiStepLabel-iconContainer {
            svg {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid var(--yellow);
                fill: var(--blue);
                text {
                    fill: var(--yellow);
                }
            }
        }

        span.Mui-active {
            svg {
                fill: var(--yellow);
                text {
                    fill: #00004F;
                }
            }
        }

        span.Mui-completed {
            svg {
                fill: var(--positif);
                border-color: var(--positif);
            }
        }

        // LABEL
        span.MuiStepLabel-label {
            color: var(--blue);
            font-family: var(--font-bold);
            font-size: 0.75rem;
            font-weight: 700;
            font-style: normal;
            text-transform: uppercase;
        }

        .css-z7uhs0-MuiStepConnector-line {
            width: 75%;
            margin: 0 auto;
        }
    }





}

