// RELEASE NOTE
.realeasenotes {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem 0;

    @media screen and (min-width: 1024px) {
        min-width: 450px;
        padding: 0 1rem;
    }

    @media screen and (min-width: 1660px){
        max-width: 40%;
    }

    > h6 {
        margin-bottom: 0.625rem;
    }

    > div > div h6 {
        margin-bottom: 0; // pagination
    }

}
