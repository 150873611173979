// WELCOME PANEL
.welcomepanel {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem 0;

    @media screen and (min-width: 1024px) {
        min-width: 330px;
        padding: 0 1rem 0 0;
    }

    @media screen and (min-width: 1660px){
        max-width: 30%;
    }


    > h6 {
        margin-bottom: 0.625rem;
    }


}
