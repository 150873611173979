// BLOCK INFOS LANDING
.blockinfos-landing {
    position: relative;
    overflow: hidden;
    max-height: 850px;

    @media screen and (min-width: 1440px) {
        max-height: 1350px;
    }

// TOP SECTION
    &--top {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: var(--blue);
        height: 50%;

        svg {
            fill: var(--white);
            width: 100%;
            height: 130px;

            @media screen and (min-width: 768px) {
                margin-top: 35px;
            }

            @media screen and (min-width: 1440px) {
                margin-top: 114px;
            }
        }
    }

// YELLOW SECTION
    &--middle {
        position: relative;
        padding-top: 8.4375rem;
        width: 95%;
        z-index: 11;

        @media screen and (min-width: 768px) {
            padding-top: 200px
        }

        @media screen and (min-width: 1440px) {
            padding-top: 375px;
        }
    }

    &--content {
        position: relative;
        background-color: var(--yellow);
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        padding: 3.125rem 6rem 200px 1.6875rem;

        @media screen and (min-width: 768px) {
            display: flex;
            padding: 3.8125rem 0 2.6875rem 4.6875rem;
        }

        @media screen and (min-width: 1440px) {
            padding-top: 125px;
            padding-bottom: 125px;
            padding-left: 135px;
        }

        &-text {
            position: relative;

            @media screen and (min-width: 768px) {
                width: 50%;
            }
        }
    }

    h3.section-title {
        font-family: var(--font-extrabold);
        font-size: clamp(1.5rem, 3vw + 0.18rem, 2.813rem);
        line-height: clamp(1.5rem, 2.9vw + 0.6rem, 3.75rem);;

        font-weight: 900;
        text-transform: uppercase;
        margin-top: 0;
        color: var(--blue);

        @media screen and (min-width: 1440px) {
            max-width: 415px;
        }
    }

    ul.blockinfos-list {
        list-style: none;
        color: var(--blue);
        padding: 0;

        @media screen and (min-width: 768px) {
            margin-bottom: 0;
        }

        li {
            font-size: 1rem;
            line-height: 25px;
            display: flex;
            align-items: center;

            span > svg {
                width: 15px;
                height: 15px;
                margin-left: 10px;
                fill: transparent;
            }
        }
    }

// BOTTOM SECTION
    &--bottom {
        position: relative;
        top: -235px;
        display: flex;
        flex-direction: column-reverse;
        z-index: 11;

        @media screen and (min-width: 768px) {
            top: -300px;
            flex-direction: row;
            align-items: flex-end;
            margin: 0;
        }

        @media screen and (min-width: 1440px) {
            align-items: center;
            top: -450px;
        }

        .cta {
            position: relative;
            width: 100%;
            align-self: flex-end;
            @media screen and (min-width: 768px) {
                width: 50%;
                padding-bottom: 50px;
                //padding: 2.5rem 1.5625rem;
            }

            @media screen and (min-width: 1440px) {
                width: 50%;
                padding-bottom: 100px;
                //padding-top: 30%;
            }
        }

        button {
            width: 100%;
            max-width: 315px;
            margin: 0 auto;
            color: var(--yellow);
            padding: 0.85rem 2.125rem;
            font-size: 14px;

            @media screen and (min-width: 1440px) {
                max-width: 400px;
            }
        }
    }

    .image-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            left: 60px;
        }

        @media screen and (min-width: 425px) {
            img {
                width: 350px;
            }
        }

        @media screen and (min-width: 768px) {
            width: 50%;
            height: 100%;

            img {
                left: 0;
                height: 100%;
                width: 450px;
            }
        }

        @media screen and (min-width: 1440px) {
            max-width: 750px;
            height: 650px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
