// USER SIDE NAVIGATION
.side-navigation {
    &--header {
        position: relative;
        width: 100%;
        min-height: 65px;

        @media (min-width: 768px){
            min-height: 125px!important;
        }

        @media screen and (min-width: 1200px) {
            min-height: 115px;
        }

        img {
            object-fit: contain;
        }
    }

    &--nav {
        padding-top: 30px!important;

        li {
            width: 90%;
            > div {
                padding: 1rem 1rem;
                border-bottom: 1px solid var(--lightBlue);
            }

            span {
                font-size: 0.75rem;
                font-family: var(--font-bold);
                font-weight: 700;
                color: var(--white);
            }

            &:last-of-type {
                div {
                    border-bottom: 0;
                }

                span {
                    color: var(--red);
                }
            }
        }
    }
}

// SUBSCRIPTION PAGE
.subscription-drawer {
    img {
        object-fit: contain;
        padding: 25px;
        @media screen and (min-width: 768px){
            padding: 0;
        }
    }
    .step-container {
        margin-top: 75px;
    }
}


// ADMIN DASHBOAD NAVIGATION
.dashboard-navigation {
    a {
        padding: 1rem 1rem;
        border-bottom: 1px solid var(--lightBlue);
        width: 90%;

        &:last-child {
            border-bottom: 0;
        }

        > div {
            min-width: 55px;
        }

        span {
            font-size: 0.75rem;
            font-family: var(--font-bold);
            font-weight: 700;
            color: var(--white);
        }
    }
}
