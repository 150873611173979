/* ALERT DETAILS */
.alert-details {
    position: relative;
    margin-bottom: 80px;

    // COL
    &--col {
        &:first-of-type{
            margin-right: 1.46875rem;
            flex:1;
            //max-width: 80px;
        }

        &:last-of-type {
            margin-left: 1.46875rem;
            flex:3;
            //width: calc(100% - 80px);
        }
    }

    // HEADER
    &--header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.25rem;
        // ICON
        &__icon {
            display: flex;
            justify-content: center;

            .icon {
                position: relative;
                display: flex;
                //width: 50px;
                width: 80px;
                height: auto;
                padding-top: 1rem;

                @media screen and (min-width: 768px){
                    //width: 80px;
                    padding-top: 1.125rem;
                }
            }
        }
        // CONTENT
        &__content {
            display: flex;
            align-items: center;
            margin-left: 1rem;

            h3 {
                color: var(--yellow);
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }

    // CONTENT
    &--content {
        margin-bottom: 2rem;
        .date {
            font-size: 0.75rem;
            font-weight: 900;
            line-height: 1.25rem;
            margin-top: 6px;
            margin-bottom: 1.875rem;
            color: var(--blue);

            @media screen and (min-width: 768px) {
                font-size: 0.875rem;
            }
        }

        .message {
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-bottom: 2rem;
            color: var(--blue);
            word-break: break-all;
        }
    }
}
