// DIALOG BOX
.dialogBox {

    h2 {
        font-family: var(--font-extrabold);
        padding: 2rem 1rem 1.5rem;
    }

    .dialogBox-content {
        padding-bottom: 0;
    }

}
