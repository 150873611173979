// PREFOOTER
.wrapper-prefooter {
    position: relative;
    background: linear-gradient(180deg, #EFF2F3 0%, rgba(239, 242, 243, 0.00) 100%);
}

.prefooter {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        margin-bottom: 8.125rem;
    }


    &--left {
        position: relative;

        @media screen and (min-width: 765px) {
            width: 50%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &--right {
        position: relative;
        padding: 3.5625rem 2rem;

        @media screen and (min-width: 768px) {
            width: 50%;
            padding: 2.3125rem;
        }

        h3, p {
            color: var(--blue);
        }

        h3.section-title {
            font-family: var(--font-extrabold);
            font-size: clamp(1.75rem, 1.2658rem + 2.0657vw, 3.125rem);
            line-height: clamp(2.375rem, 1.7808rem + 2.5352vw, 4.0625rem);
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 0;

            @media screen and (min-width: 1440px) {
                max-width: 340px;
            }
        }

        p {
            margin: 30px 0;
            max-width: 400px;
            font-size: clamp(0.875rem, 1vw + 0.39rem, 1rem);
        }

        button.btn,
        a.btn {
            border: 0;
            //padding: 1rem 3.25rem;
            padding: 0.85rem 2.125rem;
            font-size: 14px;
        }

    }
}
