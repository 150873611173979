/* TOP HEAD BAR PAGE */
.topbar {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0!important;
    min-height: 65px!important;

    @media screen and (min-width: 1200px) {
        min-height: 125px!important;
    }

    // Button
    &-burger {
        position: relative;
        background-color: var(--blue);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;

        @media screen and (min-width: 1200px){
            display: none;
        }

        button {
            margin: 0;
            padding: 0;
            border-radius: 0;
        }
    }

    // LOGO CITY
    &-city {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        width: calc(100% - 65px);

        img {
            padding: 1rem 1.375rem;
            object-fit: contain;
        }

        @media screen and (min-width: 768px) {
            width: 140px;

            img {
                padding: 1rem;
                object-fit: contain;
            }
        }

        @media screen and (min-width: 1200px) {
            display: none;
        }
    }

    // CONTENT
    &-content {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0.625rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (min-width: 768px){
            padding: 0 1rem;
            width:calc(100% - (140px + 65px));
            height: 65px;
            flex-wrap: nowrap;
        }

        @media screen and (min-width: 1200px){
            padding: 0 1.875rem;
            width: 100%;
            height: 100%;
        }

        // BRUCE
        &__banner {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            img {
                width: auto;
                height: 100%;
                max-width: 55px;
                object-fit: cover;

                @media screen and (min-width: 1200px) {
                    width: 100%;
                    max-width: 170px;
                    max-height: 125px;
                }
            }

            .first {
                font-size: 1rem!important;
                line-height: 1;

                @media screen and (min-width: 1024px) {
                    font-size: 1.25rem!important;
                    line-height: 2.5;
                }
            }

            .second {
                font-size: 0.5625rem;
                line-height: 1rem;
                color: var(--yellow);

                @media screen and (min-width: 768px) {
                    font-size: 0.6875rem;
                    line-height: 1rem;
                }

                @media screen and (min-width: 1024px){

                }

                @media screen and (min-width: 1200px) {
                    font-size: 1rem;
                    line-height: 1rem;
                }

            }
        }

        // ACTION
        &__action {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            padding: 1rem 0;

            a {
                width: 100%;
            }

            @media screen and (min-width: 1024px) {
                width: 50%;
                padding: 0;

                a {
                    width: fit-content;
                }
            }


        }

    }
}
