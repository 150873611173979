html,
body {
    background-color: var(--ultraLightGray) !important;
    font-family: var(--font-base) !important;
    font-size: 16px;
    color: var(--blue);
}

#root {
    background-color: var(--lightGray);
}

.container {
    @media screen and (min-width: 1440px) {
        max-width: 1680px;
    }
}

main,
main.MuiBox-root {
    background-color: #e4e7e8;
    padding: 0;

    > .hero-banner {
        max-width: 100%;
    }

    &.css-ex4nz6 {
        @media screen and (min-width: 768px) {
            margin-top: 65px;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 125px;
        }
    }

    .nv {
        display: none;
    }

    // BOARD WITH SPACING
    .wrapper-board {
        @media screen and (min-width: 1024px) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}

// PAGE PUBLIC
main.public {
    margin-top: 137px;

    @media screen and (min-width: 768px) {
        margin-top: 65px;
    }

    @media screen and (min-width: 1440px) {
        margin-top: 125px;
    }
}

.MuiContainer-root {
    margin: 0;

    &.container-fluid {
        @extend .container-fluid;
    }

    &.container {
        @extend .container;
    }
}

.css-1l8j5k8,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-pvk8wn-MuiDrawer-docked .MuiDrawer-paper {
    border: 0 !important;
    border-right: 0 !important;
}
