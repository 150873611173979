header.header-landing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    // NAVIGATION
    .navigation {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1.6875rem 2.1875rem;
        max-height: 100px;
        z-index: 11;

        @media screen and (min-width: 768px) {
            padding: 1.875rem 2.1875rem;
            justify-content: flex-start;
        }

        @media screen and (min-width: 1440px) {
            padding: 1.875rem 2.5rem;
        }
    }

    // LOGO
    .logo-container {
        position: relative;
        display: block;
        width: 215px;
        height: 41px;
    }

    svg.logo {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
