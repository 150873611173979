// SUBSCRIPTIONS PAGE
#subscription-page {
    .sub-wrapper {
        footer {
            flex: 1 1 auto;
            align-items: flex-end;

            .footer-container {
                padding-bottom: 35px;
            }
        }
    }

    .MuiContainer-root {
        @media screen and (min-width: 1024px){
            padding-bottom: 85px;
            padding-left: 5.125rem;
            padding-right: 5.125rem;
        }
    }

    .MuiPaper-root {
        .MuiToolbar-root {
            padding: 24px;
            background-color: var(--blue);
        }
    }
}

// SIDE NAVIGATION
.subscription-drawer {
    .MuiStepConnector-root {
        display: none;
    }

    .step-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        // CONTENT STEPS
        div.MuiStep-root {
            padding-left: 1.6875rem;
            padding-right: 3.875rem;
            margin-bottom: 1.875rem;
        }

        span.MuiStepLabel-iconContainer {
            svg {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid var(--yellow);
                fill: var(--blue);
                text {
                    fill: var(--yellow);
                }
            }
        }

        span.Mui-active {
            svg {
                fill: var(--yellow);
                text {
                    fill: #00004F;
                }
            }
        }

        span.Mui-completed {
            svg {
                fill: var(--positif);
                border-color: var(--positif);
            }
        }

        // LABEL
        span.MuiStepLabel-label {
            color: var(--white);
            font-family: var(--font-bold);
            font-size: 0.75rem;
            font-weight: 700;
            font-style: normal;
            text-transform: uppercase;
            margin-left: 0.75rem;
        }
    }
}
