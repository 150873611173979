// ADMIN TEMPLATE PAGE
.admin-table {
    border-collapse: unset!important;
    border-spacing: 10px 0;
    padding: 1.375rem;

    // BODY
    tbody {
        tr {
            max-height: 60px!important;
            height: 60px!important;
            &:nth-child(even) {
                background-color: var( --table-line);

                td {
                    border-left: 1px solid var(--white);
                    border-right: 1px solid var(--white);
                }
            }

            &:hover, &:focus {
                background-color: #ddd;
            }
        }

        td {
            border-left: 1px solid var(--ultraLightGray);
            border-right: 1px solid var(--ultraLightGray);
            padding: 0 1rem;

            &.name, &.date, &.actions {
                border-left: 0!important;
                border-right: 0!important;
            }

            &.role { border-left: 0!important; }

            &.name {
                strong {
                    font-size: 18px;
                    text-transform: capitalize;
                }
            }

            &.email, &.role, &.date {
                font-size: 0.875rem;
                text-align: left;
            }

            &.actions {
                padding-right: 1.6875rem;

                button:last-of-type {
                    margin-left: 17px;
                }
            }
        }
    }
}
