// FOOTER
footer {
    position: relative;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 1rem;

    .footer-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 1rem;

        @media screen and (min-width: 768px) {
            justify-content: space-between;
            padding: 0 2rem 2.3125rem;
        }

        @media screen and (min-width: 1440px) {
            flex-direction: row;
            padding: 0 1.5rem 2.3125rem;
        }

        p {
            margin: 0.85rem 0.5rem 0;

            @media screen and (min-width: 768px) {
                margin: 0;
            }

            &:first-child {
                flex-grow: 1;
                text-align: center;

                @media screen and (min-width: 768px) {
                    flex-grow: 0;
                    text-align: left;
                }
            }
        }

        p, a, span {
            font-size: 13px;
            color: var(--blue);
        }

        a {
            text-decoration: underline;
        }
    }
}


// FOOTER LANDING ----------------------------------------------------
footer.footer-landing {
    position: relative;
    bottom:0;
    display: flex;
    background-color: var(--blue);
    color: var(--white);
    margin: 0;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding: 0;
        z-index: 999;
        min-height: 221px;
    }

    @media screen and (min-width: 1024px) {
        min-height: 250px;
    }

    // CONTENT WRAPPER
    .footer-content {
        @media screen and (min-width: 768px){
            align-self: flex-end;
            justify-content: space-between;
        }

        @media screen and (min-width: 1600px) {
            max-width: 1440px;
            margin: 0 auto
        }
    }

    // LEFT & RIGHT SIDE
    .left-side, .right-side {
        display: flex;
        padding: 0 2.1875rem;

        @media screen and (min-width: 768px){
            padding: 0 1.875rem;
        }
    }

    .left-side {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2.8125rem;
        padding-top: 2.8125rem;

        @media screen and (min-width: 768px) {
            align-items: center;
            justify-content: flex-start;
            flex-basis: 57.5%;
            padding-right: 0;
            min-width: 441px;
        }

        @media screen and (min-width: 1024px){
            padding: 0 5.625rem;
            padding-right: 3.625rem;
            justify-content: space-between;
        }

        @media screen and (min-width: 1440px){
            padding: 0 5.625rem;
            padding-right: 5.625rem;
            justify-content: flex-start;
        }

        // LOGO
        .logo {
            width: 100px;
            height: 63px;
            margin-bottom: 1rem;

            @media screen and (min-width: 768px) {
                width: 160px;
                height: 100px;
                margin-bottom: 0;
                margin-right: 50px;
            }

            @media screen and (min-width: 1024px) {
                width: 240px;
                height: 150px;
                margin-right: 0;
            }

            @media screen and (min-width: 1440px) {
                margin-right: 165px;
            }
        }

        // CONTACT INFOS
        .contact-infos {
            display: flex;
            flex-direction: column;

            h4, p, a {
                color: #fff;
                margin-bottom: 0.5rem;
            }

            h4 {
                font-family: var(--font-bold);
                font-weight: 700;
                font-size: clamp(1.125rem, 2vw, 1.563rem);
                line-height: 35px;
            }
        }
    }

    // RIGHT SIDE
    .right-side {
        position: relative;
        align-items: flex-end;
        justify-content: flex-end;

        @media screen and (min-width: 768px){
            flex-basis: 325px;
            justify-content: center;
        }


        @media screen and (min-width: 1024px){
            flex-basis: 42.5%;
            //width: 553px;
        }

        .container-picture {
            position: relative;
            width: 100%;
            height: 100%;
        }

        // IMAGE
        img.footer-image {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media screen and (min-width: 768px) {
                position: absolute;
                width: 100%;
                height: 261px;
            }

            @media screen and (min-width: 1024px) {
                position: absolute;
                width: 100%;
                height: 350px;
            }

            @media screen and (min-width: 1440px) {
                position: absolute;
                width: 100%;
            }
        }
    }
}
