// CITIZEN TEMPLATE PAGE
.citizen-head {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.citizen-table {
    border-collapse: unset!important;
    border-spacing: 10px 0;
    padding: 1.375rem;

    // BODY
    tbody {
        tr {
            max-height: 60px!important;
            height: 60px!important;
            &:nth-child(even) {
                background-color: var( --table-line);

                td {
                    border-left: 1px solid var(--white);
                    border-right: 1px solid var(--white);
                }
            }

            &:hover, &:focus {
                background-color: #ddd;
            }
        }

        td {
            border-left: 1px solid var(--ultraLightGray);
            border-right: 1px solid var(--ultraLightGray);
            padding: 0 1rem;

            &.name, &.notif, &.actions {
                border-left: 0!important;
                border-right: 0!important;
            }

            &.date { border-left: 0!important; }

            &.name {
                strong {
                    font-size: 18px;
                }
            }

            &.postal, &.date {
                font-size: 0.875rem;
                text-align: center;
            }

            &.actions {
                padding-right: 1.6875rem;

                button:first-of-type {
                    margin-right: 17px;
                }
            }
        }
    }
}

// CITIZE MODIFIER STEPPER

// STEPPER
.new-citizen {
    .step-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }

        // CONTENT STEPS
        div.MuiStep-root {
            @media screen and (min-width: 1024px) {
                padding-right: 2rem;
            }
        }

        // CONNECTOR
        .MuiStepConnector-root {
            margin-left: 1.35rem;
        }
        .css-z7uhs0-MuiStepConnector-line {
            display: block;
            border-color: #bdbdbd;
            border-left-style: solid;
            border-left-width: 1px;
            min-height: 24px;
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }

        span.MuiStepLabel-iconContainer {
            svg {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid var(--yellow);
                fill: var(--blue);
                text {
                    fill: var(--yellow);
                }
            }
        }

        span.Mui-active {
            svg {
                fill: var(--yellow);
                text {
                    fill: #00004F;
                }
            }
        }

        span.Mui-completed {
            svg {
                fill: var(--positif);
                border-color: var(--positif);
            }
        }

        // LABEL
        span.MuiStepLabel-label {
            color: var(--blue);
            font-family: var(--font-bold);
            font-size: 0.75rem;
            font-weight: 700;
            font-style: normal;
            text-transform: uppercase;
            margin-left: 0.75rem;
        }

        .MuiStepLabel-root {
            padding: 8px 0;
        }
    }
}


