@keyframes slide-right {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-left {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}

.calendars-wrapper {
    display: flex;

    .left-calendar-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.horizontal-calendar-wrapper {
    --overflow-padding: 20px;

    display: grid;
    grid-template-columns: 60px 700px 60px;
    width: fit-content;

    p {
        margin: 0;
    }

    .horizontal-calendar {
        display: flex;
        position: relative;
        gap: 1rem;
        justify-content: center;
        width: 90%;
        justify-self: center;
        padding-top: var(--overflow-padding);
        padding-bottom: var(--overflow-padding);
        overflow: hidden;

        .day {
            display: flex;
            padding: 8px;
            gap: 0.6125rem;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            aspect-ratio: 1 / 1;
            width: 100%;
            min-width: 72px;
            height: auto;
            flex-grow: 1;
            flex-shrink: 1;
            transition: opacity 0.3s ease;
            opacity: 1;
            z-index: 2;
        }

        .day.transitioning-left {
            animation: 0.2s ease-in-out 0s slide-left;
            transition: opacity 0.3s ease;
            opacity: 0.2;
        }

        .day.transitioning-right {
            animation: 0.2s ease-in-out 0s slide-right;
            transition: opacity 0.3s ease;
            opacity: 0.2;
        }

        .selected-day-background {
            position: absolute;
            height: 100%;
            left: 50%;
            right: 50%;
            transform: translateX(-50%);
            height: calc(100% - (2 * var(--overflow-padding)));
            aspect-ratio: 1/1;
            border: 1px solid #e4e7e8;
            box-shadow:
                -10px -10px 20px #ffffff,
                10px 10px 20px #d1d9e6;
            backdrop-filter: blur(6px);
            background-color: var(--yellow);
            border-radius: 100%;
            z-index: 1;
        }
        .weekday {
            color: #bdbdbd;
        }

        .month {
            font-size: 12px;
        }

        .number {
            color: var(--blue);
            font-size: 2rem;
        }

        .selected-day {
            .weekday {
                color: var(--lightGray);
            }
        }
    }

    .horizontal-calendar::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(
            to left,
            rgba(239, 242, 243, 0),
            rgba(239, 242, 243, 1)
        );
        z-index: 10;
    }

    .horizontal-calendar::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 30px;
        background-image: linear-gradient(
            to left,
            rgba(239, 242, 243, 1),
            rgba(239, 242, 243, 0)
        );
        z-index: 10;
    }

    .arrow-wrapper {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
    }
}
