:root {
    // Colors
    --white: #fff;
    --white-40: rgba(255, 255, 255, .4);
    --black: #000;
    --black-40:  rgba(0, 0, 0, .4);
    --ultraLightGray: #E4E7E8;
    --lightGray: #EFF2F3;
    --mediumLightGray: #767676;
    --gray: #383838;
    --mediumGray: #2d2d2d;
    --darkGray: #232323;
    --table-line: #E6E8E9;
    --yellow: #FFB800;
    --blue: #00004F;
    --lightBlue: #1E1E76;
    --red: #EB5757;
    --positif: #6FCF97;
    --green: #8DBD05;
    --green-40: rgba(26, 180, 1, .4);

    // Fonts
    --font-light: "Nunito Light", sans-serif;
    --font-base: "Nunito", sans-serif;
    --font-semibold: "Nunito SemiBold", sans-serif;
    --font-bold: "Nunito Bold", sans-serif;
    --font-extrabold: "Nunito Extrabold", sans-serif;
}

// Breakpoints
$xs: 0;
$sm: 600;
$md: 900;
$lg: 1024;
$xl: 1200;
$xxl: 1536;
