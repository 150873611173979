/* HERO BANNER */
.hero-banner {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    //height: 125px;
    margin-bottom: 0.875rem !important;
    max-width: 100%;

    &-thin {
        position: relative;
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
        height: 125px;
        margin-bottom: 0.875rem !important;
        max-width: 100%;

        @media screen and (min-width: 768px) {
            margin-bottom: 30px !important;
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 35px !important;
        }

        &__img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media screen and (min-width: 768px) {
        height: 250px;
        margin-bottom: 30px !important;
    }

    @media screen and (min-width: 1440px) {
        height: 400px;
        margin-bottom: 35px !important;
    }

    // IMG
    &__img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

/* HERO LANDING */
.hero-block {
    position: relative;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        height: 67.82122905027933vh;
        min-height: 607px;
    }

    @media screen and (min-width: 1440px) {
        height: 95vh;
    }

    &--left {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        padding-top: 100px; // header + nav height
        background-color: var(--yellow);
        background-image: url("../../images/backgroung-spiral.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 100vw;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        height: 48.8268156424581vh;

        @media screen and (min-width: 375px) {
            height: 100vw;
        }

        @media screen and (min-width: 768px) {
            background-size: 70vh;
        }

        @media screen and (min-width: 1440px) {
            background-position: bottom left;
            background-size: 95vh;
        }

        @media screen and (min-width: 768px) {
            width: 50%;
            height: auto;
        }

        @media screen and (min-width: 1440px) {
            width: 57.2917%;
        }

        .hero-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-width: 565px;

            @media screen and (min-width: 768px) {
                max-width: 345px;
                height: 425px;
            }

            @media screen and (min-width: 1440px) {
                height: 100%;
                max-height: 699px;
                max-width: 565px;
                margin: 0 auto;
            }
        }
    }

    &--right {
        position: relative;
        width: 100%;
        background-color: var(--lightGray);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3.75rem 1.875rem;

        @media screen and (min-width: 768px) {
            width: 50%;
            padding: 0 7.8125%;
            padding-left: 30px;
        }

        @media screen and (min-width: 1440px) {
            width: 42.7083%;
            padding: 0;
        }

        h1,
        p {
            color: var(--blue);
        }

        h1 {
            font-family: var(--font-extrabold);
            font-size: clamp(1.75rem, 1.2658rem + 2.0657vw, 3.125rem);
            line-height: clamp(2.375rem, 1.7808rem + 2.5352vw, 4.0625rem);
            font-weight: 800;
            text-transform: uppercase;
            margin-top: 0;
        }

        p {
            font-size: clamp(0.875rem, 1vw + 0.39rem, 1rem);
        }

        button.btn {
            width: 100%;
            margin-top: 1.1875rem;
            color: var(--yellow);
            border: 0;
            cursor: pointer;
            padding: 0.85rem 2.125rem;
            font-size: 14px;
        }
    }

    &--content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1440px) {
            max-width: 63.577%;
        }

        @media screen and (min-width: 1650px) {
            max-width: 50%;
        }
    }
}
