// BASE GRID
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.container-fluid {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 0.9375rem;

    @media screen and (min-width: 768px) {
        padding: 0 1.875rem;
    }
}
