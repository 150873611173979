/* BUTTON */
.button-selected {
    &:after {
        content: " \2713";
    }
}

button.btn,
a.btn,
.btn {
    position: relative;
    font-family: var(--font-bold);
    font-weight: 700;
    line-height: 1rem;
    width: 100%;
    letter-spacing: -0.024em;
    font-size: clamp(0.875rem, 2vw + 0.25rem, 1rem);
    color: var(--white);
    background-color: var(--blue);
    padding: 0.625rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 25px;
    cursor: pointer;

    @media screen and (min-width: 425px) {
        width: fit-content;
        padding: 0.625rem 3.125rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 1rem 4rem;
    }

    @media screen and (min-width: 1200px) {
        padding: 1rem 3.3125rem;
    }

    &:hover,
    &:focus {
        color: var(--yellow);
        background-color: var(--blue);
    }

    // SIZE
    &-small {
        font-size: 0.875rem;
        padding: 0.625rem 1rem;

        @media screen and (min-width: 768px) {
            padding: 0.625rem 6.25rem;
        }
    }

    // COLOR
    &-yellow {
        background-color: var(--yellow);
        color: var(--blue);

        &:hover,
        &:focus {
            background-color: var(--blue);
            color: var(--yellow);
        }
    }

    &-blue {
        background-color: var(--blue);
        color: var(--white);

        &:hover,
        &:focus {
            background-color: var(--yellow);
            color: var(--blue);
        }
    }

    &-white {
        background-color: var(--white);
        color: var(--blue);

        &:hover,
        &:focus {
            background-color: var(--white);
            color: var(--blue);
            opacity: 0.85;
        }
    }

    &-green {
        background-color: var(--positif);
        color: var(--white);

        &:hover,
        &:focus {
            background-color: var(--white);
            color: var(--positif);
        }
    }

    &-red {
        background-color: var(--red);
        color: var(--white);

        &:hover,
        &:focus {
            background-color: var(--blue);
            color: var(--white);
        }
    }

    &-outlined {
        border: 1px solid var(--blue);
        background-color: transparent;
        color: var(--blue);

        &:hover,
        &:focus {
            background-color: var(--blue);
            color: var(--white);
        }
    }

    // FONT COLOR
    &-cwhite {
        color: var(--white);
        &:hover,
        &:focus {
            color: var(--white);
        }
    }

    // WITH ICON
    &-icon {
        &:before {
            content: "";
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 1rem;
            background-repeat: no-repeat;
            object-fit: contain;
        }

        &-document {
            &:before {
                background-image: url("../../images/icons/document.svg");
            }
        }

        &-plus {
            &:before {
                background-image: url("../../images/icons/plus.svg");
            }
        }

        &-pen {
            &:before {
                background-image: url("../../images/icons/edit.svg");
            }
        }

        &-arrowr {
            &:before {
                background-image: url("../../images/icons/arrow-right.svg");
            }
        }

        &-arrowl {
            &:before {
                background-image: url("../../images/icons/arrow-left.svg");
            }
        }

        &-checked {
            &:before {
                background-image: url("../../images/icons/check.svg");
            }
        }
    }

    // WITH DROP SHADOW
    &-shadow {
        border-radius: 25px;
        border: 2px solid #ecf0f3;
        box-shadow:
            10px 10px 20px 0px #d1d9e6,
            -10px -10px 20px 0px #fff;
        backdrop-filter: blur(6px);
    }
}

// SQUARE BUTTON
button.btn-square,
a.btn-square,
.btn-square {
    display: flex;
    flex-direction: column;
    background-color: #ffb800;
    height: 95px;
    width: 95px;
    border-radius: 20px;
    margin: 1rem;
    box-shadow:
        3px 3px 5px 0px #d1d9e6,
        -3px -3px 5px 0px #fff;

    p {
        font-family: var(--font-bold);
        font-weight: 700;
        font-size: 0.625rem;
        text-align: center;
        color: var(--blue);
        letter-spacing: -0.24px;
        line-height: 100%;
        margin: 5px;
        text-transform: initial;
    }

    div > span.material-icons {
        width: 45px;
        height: 45px;
    }

    &:hover,
    &:focus {
        background-color: #fcecc2;
    }

    & .MuiButton-startIcon {
        margin: 0px;
    }
}

// SHARE BUTTON
button.share-button,
a.share-button,
.share-button {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: var(--blue);
    font-weight: 400;
    line-height: 20px;

    .share {
        position: relative;
        width: 2.1875rem;
        height: 2.1875rem;
        padding: 8px;
        border-radius: 1.5625rem; // 25px
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--blue);
        margin-right: 0.5rem;
        box-sizing: border-box;
        max-width: 35px;
        max-height: 35px;

        &-shadow {
            border-radius: 25px;
            border: 2px solid #ecf0f3;
            box-shadow:
                10px 10px 20px 0px #d1d9e6,
                -10px -10px 20px 0px #fff;
        }
    }
}

// UNDERLINE BUTTON
button.underline-btn,
a.underline-btn,
.underline-btn {
    color: var(--blue);
    font-family: "Inter";
    font-size: 0.625rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: initial;
}

button.btn-split,
a.btn-split,
.btn-split {
    position: relative;
    width: fit-content;
    color: var(--white);
    background-color: var(--blue);
    border-color: var(--lightGray) !important;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;

    &:hover,
    &:focus {
        opacity: 0.9;
    }

    &-left {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right: 1px solid #030303;
        border-color: #030303;
        width: 100%;

        @media screen and (min-width: 425px) {
            width: fit-content;
        }

        &:hover,
        &:focus {
            background-color: var(--blue);
            opacity: 0.9;
        }
    }

    &-right {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;

        &:hover,
        &:focus {
            background-color: var(--blue);
            opacity: 0.9;
        }
    }
}

.preference-button {
    width: 100%;

    @media screen and (min-width: 425px) {
        flex: 1;
        width: auto;
    }
}
