// TITLE
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

h1, .h1 {
    font-family: var(--font-extrabold);
    font-size: clamp(1.75rem, 2.4vw + 1rem, 3.125rem);
    line-height: 3.4375rem;
}

h2, .h2 {
    font-family: var(--font-extrabold);
    font-size: clamp(1.5rem, 1.35vw + 1rem, 2.813rem);
    line-height: 2rem;

    @media screen and (min-width: 1440px) {
        font-size: 2.8125rem;
    }
}

h3, .h3,
h3.page-title {
    font-size: clamp(1.25rem, 1.35vw + 1rem, 1.875rem);
    font-family: var(--font-extrabold);
    font-weight: 900;
    font-style: normal;
    line-height: 3.4375rem; /* 183.333% */
}

h4, .h4 {
    font-size: clamp(1rem, 1.35vw + 1rem, 1.125rem)!important;
    font-family: var(--font-extrabold)!important;
    font-weight: 900!important;
    font-style: normal!important;
    line-height: 1.5rem!important; /* 183.333% */
}

h6, .h6 {
    font-family: var(--font-extrabold)!important;
    font-size: clamp(1rem, 1.35vw + 1rem, 1rem)!important;
    font-weight: 900!important;
    line-height: 1.5!important;
}


// TEXT ELEMENTS
p, a, span, div, th, td, tr, ul, ol, li, button,
tr > th.css-13x8jed-MuiTableCell-root,
tr > td.css-1ex1afd-MuiTableCell-root {
    font-family: var(--font-base);
    font-weight: 400;
    letter-spacing: 0;
    font-size: clamp(0.875rem, 2vw + 0.25rem, 1rem);
    line-height: 1.25rem;
}

p > b,
b > p,
p > strong,
strong > p,
a > b,
b > a,
a > strong,
strong > a,
span > b,
b > span,
span > strong,
strong > span {
    font-family: var(--font-bold);
    font-weight: 700;
    line-height: 1.25rem;
    text-decoration: none;
}

a {
    text-decoration: none;
}


