// FORM COMPONENT
.fields-container {
    position: relative;

    &.field-search {
        input[type="text"]{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.125rem 1rem 1rem 1.25rem;

            &::placeholder {
                font-size: 1rem;
                font-family: var(--font-base);
                font-weight: 300;
                line-height: 1rem;
                letter-spacing: -0.384px;
                color:#828282;
            }

            &:after {
                content: '';
                display: block;
                width: 15px;
                height: 15px;
                background-image:url('../../images/icons/search.svg');
            }

            &:-webkit-autofill {
                background-color: transparent!important;
            }
        }

        fieldset {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            padding-top: 0.3125rem;
            padding-right: 1.375rem;
            border-radius: 25px;
            box-shadow: 6px 6px 10px 0px #D1D9E6 inset, -6px -6px 10px 0px #FFF inset!important;
            border: 0!important;

            &:after {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                background-image:url('../../images/icons/search.svg');
                background-repeat: no-repeat;
            }
        }
    }
}

input[type="text"]{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.125rem 1rem 1rem 1.25rem;

    &::placeholder {
        font-size: 1rem;
        font-family: var(--font-base);
        font-weight: 300;
        line-height: 1rem;
        letter-spacing: -0.384px;
        color:#828282;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background-color: transparent!important;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: var(--blue);
    -webkit-box-shadow: 0 0 0px 1000px rgba(255,255,255, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
}

fieldset {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 0.3125rem;
    padding-right: 1.375rem;
    border-radius: 25px!important;
    box-shadow: 6px 6px 10px 0px #D1D9E6 inset, -6px -6px 10px 0px #FFF inset!important;
    border: 0!important;
}


// SUBSCRIPTION PAGE
#subscription-page,
.public,
.my-profile,
.main-content {
    .switch-label {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        margin-bottom: 20px;
        padding: 0 1rem;

        @media screen and (min-width: 768px) {
            padding: 0;
        }

        span {
            font-family: var(--font-base);
            font-size: 0.875rem;
            line-height: 1.5rem;
            font-weight: 400;
            font-style: normal;
            color:var(--blue);
        }
    }

    .switch-button {
        width: 70px;

        > .Mui-checked {
            transform: translateX(25px);
        }

        .MuiSwitch-thumb {
            background-color: var(--yellow);
            width: 25px;
            height: 25px;
            filter: drop-shadow(3px 3px 5px #D1D9E6) drop-shadow(-3px -3px 5px #FFF);
        }

        .MuiSwitch-track {
            width: 70px;
            height: 20px;
        }

        .Mui-checked+.MuiSwitch-track {
            background-color: var(--positif)!important;
            box-shadow: 6px 6px 10px 0px #5EAD7F inset, -6px -6px 10px 0px #81FAB3 inset;
        }

        .MuiSwitch-track {
            background-color: var(--white)!important;
            box-shadow: 6px 6px 10px 0px #D1D9E6 inset, -6px -6px 10px 0px #FFF inset;
            backdrop-filter: blur(6px);
            border-radius: 25px;
        }
    }
}

// LOGIN PAGE
.login-page {
    #email,
    #password {
        + fieldset {
            top: 0!important;
        }
    }
}

