// ACTION PANELS
.actionpanels {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem 0;
    padding-right: 0;
    box-sizing: content-box;

    @media screen and (min-width: 1024px) {
        min-width: 330px;
        padding: 0 1rem;
        padding-right: 0;
    }

    @media screen and (min-width: 1660px){
        max-width: 30%;
    }

    > h6 {
        margin-bottom: 10px;
    }

    .MuiGrid-container {
        @media screen and (min-width: 1600px) {
            .MuiGrid-item {
                max-width: 96px;
                margin-right: 5%;
            }
        }


    }




}
