// BLOCK CENTER

.block-center {
    position: relative;
    width: 100%;
    background: linear-gradient(180deg, #EFF2F3 0%, rgba(239, 242, 243, 0.00) 100%);
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        min-height: 636px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 100;

        @media screen and (min-width: 1440px) {
            max-width: 1230px;
        }
    }
}
